
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import assetsModule from '@/store/modules/assetsModule';
import {
  getComponent,
  getISOStringWithLocalOffset,
  sleep,
} from '@/utils/helpers';
import draggable from 'vuedraggable';
import Multiselect from 'vue-multiselect';
import metaDataModule from '@/store/modules/metaDataModule';
import DateRangePicker from 'vue2-daterange-picker';
// you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import accountModule from '@/store/modules/accountModule';
import tasqsListModule from '@/store/modules/tasqsListModule';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import tasqActionsModule from '@/store/modules/tasqActionsModule';
import workflowModule from '@/store/modules/workflowModule';
import { mixins } from 'vue-class-component';
import GptMixin from './GptMixin';
import axiosClient from '@/lib/rest/axiosClient';
import gptModule from '@/store/modules/gptModule';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    Multiselect,
    TasqsDetails: () => getComponent('tasqs/TasqsDetails'),
    Dropdown: () => getComponent('common/Dropdown'),
    TasqsMobile: () => getComponent('tasqs/TasqDetailMobile'),
    GptAutoSuggestion: () => getComponent('gpt/GptAutoSuggestionV2'),
    DateRangePicker,
    draggable,
  },
})
export default class Groups extends mixins(GptMixin) {
  @Prop({ type: Object, required: false }) tasq?: TasqJob;

  selectedActions: any = [];

  isSavingNewTasq = false;

  selectedPad: any = null;

  dataLoading = true;

  startDate = new Date().toISOString();

  nodeLevel = 'Well';

  selectedAssignee: any = [
    {
      value: workflowModule.user.email,
      text: `${workflowModule.user.firstName} ${workflowModule.user.lastName}`,
    },
  ];

  selectedNode: any = [];

  actionErrors: any = [];

  selectedWell: any = null;

  windowWidth = window.innerWidth;

  selectedActionCategory = '';

  comment = '';

  selectedAssetType = 'Well';

  selectedPerson = {
    value: workflowModule.user.email,
    text: `${workflowModule.user.firstName} ${workflowModule.user.lastName}`,
  };

  stepNo = 0;

  similarTasqs: any = [];

  categoryOptions: any = [];

  actionItems: any = [];

  get enabledPads() {
    return assetsModule.enabledPads;
  }

  get isSubmitBtnEnabled() {
    return (
      this.comment && this.selectedNode.length && this.selectedAssignee.length
    );
  }

  get reassignUsers() {
    return accountModule.reassignmentList
      .map((i) => ({
        // @ts-ignore
        text: i.name,
        value: i.email,
      }))
      .sort((a, b) => a.text.localeCompare(b.text));
  }

  get enabledWells() {
    return assetsModule.enabledWells;
  }

  get enabledWellsMapping() {
    if (this.nodeLevel && this.nodeLevel.toLowerCase() == 'pad') {
      return assetsModule.enabledPads.map((well) => {
        return well.text;
      });
    } else {
      return assetsModule.enabledWells.map((well) => {
        return well.text;
      });
    }
  }

  async created() {
    this.dataLoading = true;
    const promiseList: any = [];
    promiseList.push(assetsModule.getEnabledWells());
    promiseList.push(assetsModule.getEnabledPads());

    Promise.all(promiseList).then(
      async () => {
        const currentRouteName: any = this.$route.name;
        if (this.tasq && this.tasq.wellName) {
          this.selectedNode = [this.tasq.wellName];
          this.nodeLevel = this.tasq.level;
        } else if (currentRouteName === 'Pads') {
          this.nodeLevel = 'Pad';
        }
        // console.log('resolved');
        this.dataLoading = false;
      },
      (err) => {
        console.log('Error:');
        console.log(err);
      }
    );
  }

  get isMobile() {
    return this.windowWidth < 768;
  }

  async saveManualTasq() {
    this.dataLoading = true;
    this.isSavingNewTasq = true;

    try {
      const payload = {
        operator: this.operatorDetails['operatorName'],
        node_name: this.selectedNode[0],
        node_level: this.nodeLevel,
        source_type: 'app',
        value_type: 'manual tasq',
        time: this.startDate
          ? getISOStringWithLocalOffset(this.startDate)
          : this.startDate,
        data: {
          comment: this.comment,
          assignee: this.selectedAssignee[0].value,
        },
      };
      console.log(payload);

      await axiosClient.post('/response-store/', {
        ...payload,
      });

      await sleep(1200);

      await gptModule.getAllActiveManualTasqs({
        operatorName: this.operatorDetails['operatorName'],
      });

      this.$tasqAlert({
        title: 'Success',
        message: 'The tasq has been successfully submitted.',
        type: 'success',
      });
    } catch (error) {
      console.log(error);
      this.$tasqAlert({
        title: 'Error',
        message: 'Failed to submit tasq. Please try again later.',
        type: 'error',
      });
    }

    // $emit('close-manual-tasq-popup')"

    gptModule.setLoadManualEntries(true);
    this.dataLoading = false;
    this.$nextTick(() => {
      gptModule.setLoadManualEntries(false);
    });
    this.$emit('close-manual-tasq-popup');
  }
}
